import React, { useState, useEffect } from "react";
import { Select, Button, Form, Input, Spin } from "antd";
// import { DownOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";

// import axios from "axios";

import styled from "styled-components";

import { SuspenseImg } from "../../SuspenseImage/SuspenseImage";
import axios from "axios";
import ErrorModal from "../ErrorModal/ErrorModal";
import { useNavigate } from "react-router-dom";
import Timer from "../ShowTimer/ShowTimer";
// import bgImage from "../../assets/images/bgimage.jpg";

const { Option } = Select;

const Main = ({
  logo,
  serviceType,
  price,
  ani,
  service_id,
  product_id,
  clickid,
  isHeaderFlow,
}) => {
  // console.log("MAIN JS ", typeof(ani));
  // isHeaderFlow = true;
  const [cookies, setCookie] = useCookies(["toonflix"]);
  const navigate = useNavigate();
  //   console.log("hello main");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [ShowTimer, setShowTimer] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [msisdn, setMsisdn] = useState("");
  const [packType, setPackType] = useState("");
  const [isUserExist, setisUserExist] = useState(0);
  const [txCode, setTxCode] = useState(null);
  const [nonHeaderPack, setNonHeaderPack] = useState(true);
  // useEffect(() => {
  //   const fetchToken = async () => {
  //     const response = await axios.get("http://localhost:5980/redirect");
  //     console.log("respone ", response.data.accessToken);
  //   };
  //   fetchToken();
  // }, []);

  console.log("serviceType ", serviceType);

  const [form] = Form.useForm();
  const [otpform] = Form.useForm();
  const onGenderChange = (value) => {
    switch (value) {
      case "daily":
        form.setFieldsValue({
          pack: "Toonflix Daily",
        });
        return;

      case "weekly":
        form.setFieldsValue({
          pack: "Toonflix Weekly",
        });
        return;

      case "monthly":
        form.setFieldsValue({
          pack: "Toonflix Monthly",
        });
    }
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const onFinish = async (values) => {
    try {
      // setMsisdn(values.ani);
      setMsisdn(values.ani);
      // setPackType(values.pack);
      setLoading(true);
      console.log("FINISH", values);
      const response = await axios.post(
        "https://api.afg.toon-flix.com/sendsms",
        {
          ani: values.ani,
          type: "otp",
          serviceType: "TOONFLIX",
        }
      );
      setLoading(false);
      console.log("response ", response);
      setTxCode(response.data.txCode);
      setisUserExist(response.data.ifExist);

      setShowOTP(true);
    } catch (e) {
      setLoading(false);
      console.log(e);
      alert("Some thing went wrong", e);
    }
  };

  // const onHeaderFlow = async (values) => {
  //   console.log("values ", values);
  //   try {
  //     setMsisdn(values.ani);
  //     setPackType(values.pack);
  //     setLoading(true);
  //     const sendSubscription = await axios.get(
  //       `http://api.afg.toon-flix.com/user/register?ani=${values.ani}&service_type=TOONFLIX&isSubscribe=true&pack_type=${values.pack}&mode=web`
  //     );
  //     console.log("sendSubscription ", sendSubscription);
  //     otpform.resetFields();
  //     setCookie("toonflix", msisdn, { path: "/" });
  //     // navigate("/toonflix/redirect");
  //     window.location.href = `https://afg1.toon-flix.com/toonflix/redirect/?status=1&ani=${msisdn}`;
  //   } catch (e) {
  //     // navigate("/redirect");
  //     // otpform.resetFields();
  //     otpform.resetFields();
  //     console.log("error in subs", e.response.data.error.message);
  //     console.log(
  //       " e.response.data.error.errorCode ",
  //       e.response.data.error.errorCode
  //     );
  //     // setShowOTP(false);
  //     if (e.response.data.error.errorCode === "5201004") {
  //       setCookie("toonflix", msisdn, { path: "/" });
  //       // navigate("/redirect");
  //       window.location.href = `https://afg1.toon-flix.com/toonflix/redirect/?status=1&ani=${msisdn}`;
  //     } else alert(e.response.data.error.message);
  //   }
  // };
  const onFinishOTP = async (values) => {
    console.log(" INside on Finish OTP");
    try {
      setLoading(false);
      setShowOTP(false);
      console.log(values);
      const sendSubscriptionResponse = await axios.get(
        `https://api.afg.toon-flix.com/user/register-auth?ani=${msisdn}&service_type=TOONFLIX&isSubscribe=true&pack_type=Toonflix Daily&mode=web&authCode=${values.otp}&trxId=${txCode}`
      );
      console.log("sendSubscriptionResponse ", sendSubscriptionResponse);
      otpform.resetFields();
      // setShowOTP(values.otp);
      if (
        (sendSubscriptionResponse.data.message = "User activated Successfully")
      ) {
        setCookie("toonflix", msisdn, { path: "/" });
        navigate(`/toonflix/redirect/?status=1&ani=${msisdn}&status=1`);
        // window.location.href = `https://afg1.toon-flix.com/toonflix/redirect/?status=1&ani=${msisdn}`;
        //redirect to https page
      } else {
        console.log(
          "sendSubscriptionResponse  in else ",
          sendSubscriptionResponse
        );
        alert("Some thing went wrong ", sendSubscriptionResponse.data);
      }
    } catch (err) {
      otpform.resetFields();
      console.log(err.response.data.error);
      console.log(
        "err.response.data.error.code ",
        err.response.data.error.code
      );
      if (err.response.data.error.errorCode === "5201004") {
        console.log("let me sub user now into db");
        const sendSubscriptionResponse = await axios.get(
          `https://api.afg.toon-flix.com/user/existingUserEntry?ani=${msisdn}&service_type=TOONFLIX&isSubscribe=true&pack_type=Toonflix Daily`
        );
        console.log("sendSubscriptionResponse ", sendSubscriptionResponse);
        setCookie("toonflix", msisdn, { path: "/" });
        // window.location.href = `https://afg1.toon-flix.com/toonflix/redirect/?status=1&ani=${msisdn}`;

        //redirect to https page
      } else if (err.response.data.error.code === 8001022) {
        alert(err.response.data.error.message);
      }
    }
  };

  const formSubmitAfterPackChoose = async (values) => {
    console.log("formSubmitAfterPackChoose ");
    try {
      // const sendSubscriptionResponse = await axios.get(
      //   `http://api.afg.toon-flix.com/user/register-auth?ani=${msisdn}&service_type=TOONFLIX&isSubscribe=true&pack_type=Toonflix Daily&mode=web&authCode=${values.otp}&trxId=${txCode}`
      // );
      const sendSubscription = await axios.get(
        `https://api.afg.toon-flix.com/user/register-auth?ani=${msisdn}&service_type=TOONFLIX&isSubscribe=true&pack_type=Toonflix Daily&mode=web&authCode=${values.otp}&trxId=${txCode}`
      );
      console.log("sendSubscription ", sendSubscription);
      otpform.resetFields();
      setCookie("toonflix", msisdn, { path: "/" });
      // navigate("/toonflix/redirect");
      //commented intenially
      // window.location.href = `https://afg1.toon-flix.com/toonflix/redirect/?status=1&ani=${msisdn}`;
    } catch (e) {
      // navigate("/redirect");
      // otpform.resetFields();
      otpform.resetFields();
      console.log("error in subs", e.response.data.error.message);
      setShowOTP(false);
      if (e.response.data.error.errorCode === "5201004") {
        setCookie("toonflix", msisdn, { path: "/" });
        navigate(`/toonflix/redirect/?status=1&ani=${msisdn}&status=1`);
        // window.location.href = `https://afg1.toon-flix.com/toonflix/redirect/?status=1&ani=${msisdn}`;
      } else alert(e.response.data.error.message);
      // }
    }
  };

  console.log("ANI===>>>> isHeaderFlow ", isUserExist);

  React.useEffect(() => {
    if (ani !== "null")
      form.setFieldsValue({
        ani: ani,
      });
  }, []);

  return (
    <MainStyled>
      <div className="inner-items">
        <SuspenseImg src={logo} alt="logo" />

        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {nonHeaderPack && !showOTP ? (
            <Form
              form={form}
              name="control-hooks"
              onFinish={
                onFinish
                // isUserExist === 0 ? onFinish : formSubmitAfterPackChoose
              }
            >
              <Form.Item
                name="ani"
                label="Enter Number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                {loading ? (
                  <Input readOnly />
                ) : ani !== "null" ? (
                  <Input placeholder={ani} readOnly />
                ) : (
                  <Input placeholder="enter number" />
                )}
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ minWidth: "100px" }}
                >
                  {loading ? <Spin /> : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          ) : null}

          {/* show otp when user enter number */}
          {showOTP ? (
            // {showOTP && !nonHeaderPack ? (
            <Form
              form={otpform}
              name="control-hooks"
              // onFinish={onFinishOTP}
              onFinish={
                isUserExist === 0 ? onFinishOTP : formSubmitAfterPackChoose
              }
              autoComplete="off"
            >
              {isUserExist === 0 ? (
                <Form.Item
                  name="pack"
                  label="Select Pack"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Pack"
                    onChange={onGenderChange}
                    allowClear
                    disabled={loading ? true : false}
                  >
                    <Option value="daily">5 AFN Daily</Option>
                    <Option value="weekly">20 AFN Weekly</Option>
                    <Option value="monthly">40 AFN Monthly</Option>
                  </Select>
                </Form.Item>
              ) : null}

              <Form.Item
                name="otp"
                label="Enter OTP"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                {loading ? <Input readOnly /> : <Input />}
              </Form.Item>
              <Timer OnCancel={() => setShowOTP(false)} />
              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ minWidth: "100px" }}
                >
                  {loading ? <Spin /> : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          ) : null}
        </div>

        {/* </Tooltip> */}

        {/* {isError ? (
          <ErrorModal
            message={isError}
            OnCancel={() => setIsError("")}
            visible={isError ? true : false}
            ShowTimer={ShowTimer}
          />
        ) : null} */}

        {/* <div className="footer-info">
          {" "}
          <h3>Terms & Conditions</h3> <h3>for help,call us 135</h3>
        </div> */}
      </div>
    </MainStyled>
  );
};

export default React.memo(Main);

const MainStyled = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  .custom-button {
    border-radius: 8px;
    background-color: #799f0c;
    font-weight: 800;
  }
  .inner-items {
    height: 80%;
    width: 100%;
    padding: 20px;
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: auto;
      max-height: 50px;
      margin-bottom: 20px;
    }
  }
  .ant-form-item-label > label {
    min-width: 111px;
  }
  h2 {
    color: white;
    padding: 10px;
    font-weight: 800;
  }
  .footer-info {
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    h3 {
      color: white;
    }
  }
  .dropdown-button {
    padding: 20px;
    border: 10px;
    /* width: 50%; */
  }
`;
