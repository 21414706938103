import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { Button, Input } from "antd";
import { SuspenseImg } from "../../SuspenseImage/SuspenseImage";
import ErrorModal from "../ErrorModal/ErrorModal";

const ShowLoginInput = ({
  logo,
  serviceType,
  price,
  ani,
  service_id,
  product_id,
  clickid,
}) => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState("");
  const [ShowTimer, setShowTimer] = useState(false);
  const [number, setNumber] = useState("");

  const formSubmit = async () => {
    try {
      const checkUser = await axios.post(
        "https://kunim.mtn.gamewins.co.za/register",
        {
          ani: number,
          service_type: serviceType,
          product_id: product_id,
          service_id: service_id,
          clickId: clickid,
          isSubscribe: false,
          uuid: 12134,
          ext_id: 1234567,
        }
      );

      console.log(checkUser.data.result);

      if (checkUser.data.result <= 0) {
        const chargingResponse = await axios.post(
          "https://kunim.mtn.gamewins.co.za/mtnghana/chargeuser/deduct-balance",
          {
            ani: number,
            service_type: serviceType,
            product_id: product_id,
            service_id: service_id,
            clickId: clickid,
          }
        );
        console.log(chargingResponse.data);
        // if (
        //   chargingResponse.data.message ===
        //   "Dear User, Please confirm your subsription via USSD"
        // ) {
        setIsError("Please acccept req via USSD");
        setShowTimer(true);

        const checkCallbackResponse = await axios.get(
          `https://kunim.mtn.gamewins.co.za/register/checkUssdResponse?ani=${number}&serviceName=${serviceType}`
        );
        console.log(checkCallbackResponse.data);

        navigate(`/kidszone/redirect/?ani=${ani}&servicename=${serviceType}`);
        // } else {
        //   setIsError(chargingResponse.data.message);
        //   setShowTimer(false);
        // }
      } else {
        navigate(
          `/kidszone/redirect/?msisdn=${number}&servicename=${serviceType}`
        );
      }
    } catch (err) {
      console.log(err.response);
      console.log(err.response.data);
      setShowTimer(false);
      if (err.response.data.message == "USSD request not confirmed") {
        console.log("NOT CONFIRMED");
        setIsError(err.response.message);
        // navigate(
        //   `/gameomania/redirect/?ani=${number}&servicename=${serviceType}`
        // );
      } else {
        console.log(err.response.data.message.error);
        setIsError(err.response.data.message.error);
      }
    }
  };

  //   console.log("hi");
  return (
    <ShowLoginInputStyled>
      <div className="inner-items">
        {" "}
        <SuspenseImg src={logo} alt="logo" />
        <h2>Subscription AFN {price} Per / Day</h2>
        <Input
          value={number}
          placeholder="Enter 9 Digit MTN Number"
          maxLength="9"
          size="large"
          onChange={(e) => setNumber(e.target.value)}
        />
        <Button
          type="primary"
          className="custom-button"
          size="large"
          onClick={formSubmit}
        >
          Subscribe
        </Button>
        {isError ? (
          <ErrorModal
            message={isError}
            OnCancel={() => setIsError("")}
            visible={isError ? true : false}
            ShowTimer={ShowTimer}
          />
        ) : null}
      </div>
    </ShowLoginInputStyled>
  );
};

export default ShowLoginInput;

const ShowLoginInputStyled = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  .custom-button {
    border-radius: 8px;
    background-color: #799f0c;
    font-weight: 800;
    margin-top: 20px;
  }
  .inner-items {
    height: 80%;
    width: 100%;
    padding: 20px;
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 50%;
    }
  }

  h2 {
    color: white;
    padding: 10px;
    font-weight: 800;
  }
  .footer-info {
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    h3 {
      color: white;
    }
  }
`;
