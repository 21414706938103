import React, { useState } from "react";
import styled from "styled-components";

import logo from "../../assets/images/toonflix.png";
import SideBar from "../SideBar/SideBar";
import { Link } from "react-router-dom";

const Header = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <HeaderStyled>
      {/* <div className="menu-icon" onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      </div> */}
      {/* 
      <SideBar collapsed={collapsed} /> */}
      <Link to="/toonflix/redirect">
        <img src={logo} alt="logo" />
      </Link>
    </HeaderStyled>
  );
};

export default Header;

const HeaderStyled = styled.div`
  min-height: 7vh;
  min-width: 100vw;
  background-image: linear-gradient(135deg, #52e5e7 10%, #130cb7 100%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    /* position: absolute; */
    height: 40px;
    width: 150px;
  }
  .menu-icon {
    position: absolute;
    left: 10px;
    font-size: 30px;
    font-weight: 800;
    z-index: 10;
  }
`;
