import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import userContext from "../context/UserContext";
import ErrorModal from "../ErrorModal/ErrorModal";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Spin } from "antd";

// import UserState from "../context/UserState";

const Layout = (props) => {
  let location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(true);

  console.log("location ANI ", queryParams.get("ani"));

  console.log("PARAMS ", queryParams.get("ani"));
  console.log("status ", queryParams.get("status"));
  const [cookies, setCookie, removeCookie] = useCookies(["toonflix"]);

  const { state } = React.useContext(userContext);

  const { status } = state;
  console.log("Hello");
  console.log("cookies.toonflix ", cookies.toonflix);
  console.log("queryParams action", queryParams.get("action"));

  useEffect(() => {
    if (queryParams.get("action") !== null) {
      console.log("REMOVING COOKIE NOW");
      setLoading(false);
      // removeCookie("toonflix");
      removeCookie("toonflix", { path: "/" });
      navigate("/toonflix/home");
    }
  }, []);

  useEffect(() => {
    if (queryParams.get("action") === null) {
      if (
        cookies.toonflix === undefined &&
        queryParams.get("status") === null
      ) {
        setLoading(false);
        // return navigate("/toonflix/home");
        window.location = "http://awcc-af.telenity.com/lph/toonflix/home";
        // return navigate("http://awcc-af.telenity.com/toonflix/home");
      } else if (queryParams.get("status") === "0") {
        setLoading(false);
        // alert("Invalid url");
        return navigate(
          `/toonflix/home/?ani=${queryParams.get(
            "ani"
          )}&status=${queryParams.get("status")}`
        );
      } else if (cookies.toonflix !== undefined) {
        setLoading(false);
        console.log("Here nowww");
        navigate("/toonflix/redirect/");
        // window.location = "https://afg1.toon-flix.com/toonflix/redirect";
      } else {
        if (
          queryParams.get("status") === "1" &&
          cookies.toonflix !== undefined &&
          cookies.toonflix !== null
        ) {
          setCookie("toonflix", queryParams.get("ani"), { path: "/" });
          setLoading(false);
          navigate("/toonflix/redirect");
        } else if (cookies.toonflix === undefined) {
          if (queryParams.get("status") === "1") {
            setCookie("toonflix", queryParams.get("ani"), { path: "/" });
            setLoading(false);
          } else {
            console.log("HEEEEEE");
            setLoading(false);
            window.location = "http://awcc-af.telenity.com/lph/toonflix/home";
          }

          // navigate("/toonflix/redirect");
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   if (cookies.toonflix === undefined && queryParams.get("status") === null) {
  //     setLoading(false);
  //     return navigate("/toonflix/home");
  //   } else if (queryParams.get("status") === "0") {
  //     setLoading(false);
  //     return navigate(
  //       `/home/?ani=${queryParams.get("ani")} &status=${queryParams.get(
  //         "status"
  //       )}`
  //     );
  //   } else {
  //     if (queryParams.get("status") === "1") {
  //       setLoading(false);
  //       navigate("/toonflix/redirect");
  //     } else if (cookies.toonflix !== undefined) {
  //       console.log("HEEEEEE");
  //       setLoading(false);
  //       navigate("/toonflix/redirect");
  //     }
  //   }
  // }, []);
  if (loading)
    return (
      <div
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );

  return (
    <>
      <div>
        {!location.pathname.includes("home") ? <Header /> : null}
        {/* {cookies.toonflix === "null" && location.pathname !== "/home" ? (
          <ErrorModal message="Invalid User" />
        ) : null} */}
        <div className="content">{props.children}</div>
      </div>
    </>
  );
};

export default Layout;

const LayoutStyled = styled.div``;
