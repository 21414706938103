import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

// import "./App.css";

import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

function App() {
  console.log("window.location ", window.location);
  const navigate = useNavigate();
  console.log("window.location ", window.location.pathname.toLowerCase());
  // console.log(useContext(userContext));
  useEffect(() => {
    if (window.location.pathname === "/") navigate("/toonflix/home");
  }, []);

  console.log("window.location.href ", window.location.pathname);

  return <div></div>;
}

export default App;
